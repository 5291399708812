import { Mark } from '@tiptap/core';

// Allow for a custom element <highlight> in the markup. 
const Highlight = Mark.create({
    name: 'highlight',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'highlight',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['highlight', HTMLAttributes /* core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)*/, 0];
    },
    addCommands() {
        return {
        };
    },
    addKeyboardShortcuts() {
        return {};
    },
    addInputRules() {
        return [
        ];
    },
    addPasteRules() {
        return [];
    },
});


export { Highlight, Highlight as default };
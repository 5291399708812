<template>
    <div>        
        <div class="filter py-2 px-2">
            <v-row dense>
                <v-col sxm="auto" class="flex v-center">
                    
                </v-col>
                <v-col sm="auto">
                    <NumberField block class="w-2" :min="0" :max="53" placeholder="week" v-model="filter.week"></NumberField>
                </v-col>
                <v-col sm="auto">
                    <EmployeeCombo v-model="filter.id_employee"></EmployeeCombo>
                </v-col>
                <v-col sm="auto">
                    <ActionButton icon="search" main success @click='()=>dt.search()'>Zoek</ActionButton>
                </v-col>
                <v-col sm="auto" class="text-right">
                    <ActionButton icon="pdf" main primary @click="()=>dt.onStartCheckBox(null, 'pdf')">PDF</ActionButton>
                </v-col>
                <v-col sm="auto" class="text-right">
                    <ActionButton icon="excel" main primary @click="onExportExcel">Excel</ActionButton>
                </v-col>
            </v-row>
        </div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="hourlist.open" @click="()=>dlg.open('hourlist', {id: item.id_hourlist})"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project_hours.export" @click="()=>dt.onStartCheckBox(item, 'pdf')"></ActionMenuItem>
            </template>

            <template v-slot:customcommands="{bulkAction, selectedCount}">
                <ActionButton v-if="bulkAction=='pdf'" ml dusk="btn-bulk-pdf" main primary icon="pdf"  @click="onPdf" :disabled="!selectedCount" >Exporteer naar PDF</ActionButton>
            </template>

        </Datatable>

        <Prompt title="Exporteer naar PDF" save="Ga door" :model="prompt">
            <Row label="Bestandsnaam">
                <TextField :rules='prompt.mandatory' v-model='prompt.filename'></TextField>   
            </Row>
            <Row label="Toon geboortedatum">
                <Checkbox v-model='prompt.show_birthday'></Checkbox>
            </Row>
            <Row label="Toon BSN">
                <Checkbox v-model='prompt.show_bsn'></Checkbox>
            </Row>
            <Row label="Medewerker uren">
                <Radiogroup xmodel="prompt" v-model="prompt.group">
                    <v-row dense>
                        <v-col>
                            <Radio value="day" label="Per dag"/>
                        </v-col>
                    </v-row>                                     
                    <v-row dense>
                        <v-col>
                            <Radio value="week" label="Per week"/>
                        </v-col>
                    </v-row>                            
                </Radiogroup>
            </Row>

        </Prompt>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { project_hours as api } from '@app/api'
    import dlg from '@app/dlg'
    import ActionButton from '@controls/buttons/ActionButton'
    import {date, time} from '@lib/date'
    import string from '@lib/string'
    import NumberField from '@controls/input/NumberField'
    import TextField from '@controls/input/TextField'
    import {computed, ref} from 'vue'
    import noty from '@shared/lib/noty'
    import Prompt from '@shared/ui/dialogs/DialogCustomPrompt'
    import clsDialogCustomPrompt from '@cls/clsDialogCustomPrompt'
    import Row from '@controls/forms/Row'
    import Checkbox from '@controls/check/Checkbox.vue'
    import Radiogroup from "@controls/check/Radiogroup.vue";
    import Radio from "@controls/check/Radio.vue";
    import EmployeeCombo from '@controls/combo/Employee'

    const filter = ref( {
        week: null, 
        employee: null,
        id_employee: null
    });

    let prompt = ref(new clsDialogCustomPrompt(["filename", "show_birthday", "show_bsn", "group"]));

    const props = defineProps({
        model: {
            type: [Object]
        },
    });
    function fmtStatus(v,V,item) {
        return !v?'Open':'Gereed';
    }
    function fmtDay(v, V, item) {
        return date.fmt.dow(v);
    }
    function fmtHHMM(v,V,item) {
        if (!item.hr_start_time&&!item.hr_end_time) {
            return '-';
        }
        return time.minutes2hourminutes(v, false);
    }
    function fmtWeek(v, V, item) {
        var y = item?.hl_year || "";        
        var w = string.right(`00${v}`, 2);        
        return `${y}-${w}`;
    }            
    function fmtExcelHours(v,V,item) {
        return time.minutes2hourdecimal(v, false)
    }

    var headers = [
        { type: 'cmd' },
		{ excel: false, value: "hl_completed", text: "Gereed", class: 'w-3', type: 'yesno'},
		{ value: "hl_week",  text: "Week",    class: 'w-3', type: 'open', fmtExcel: fmtWeek, fmt: fmtWeek, open: (item)=>dlg.open('hourlist', {id: item.id_hourlist})},
		{ value: "employee",                  text: "Medewerker",                 },
        { value: "hr_date"             , text: "Dag"        , fmtExcel:(v,V,item)=>date.fmt.local(v), fmt: fmtDay},
        { value: "hr_start_time"       , text: "Van"        , fmtExcel:fmtHHMM,fmt:fmtHHMM, class:"w-2_5"},
        { value: "hr_end_time"         , text: "Tot"        , fmtExcel:fmtHHMM,fmt:fmtHHMM, class:"w-2_5"},
        { value: "hr_total_minutes"    , text: "Uren"       , fmtExcel:fmtExcelHours, type: 'dhhmm', class:"w-2_5"},
        { value: "hourtype"            , text: "Type"       },
        { excel: false, value: "tariff"              , text: "Tarief"     },
        { excel: false, value: "amount_intern"       , text: "Kosten", type:'amount'     },
        { value: "remark"           , text: "Opmerking"  },
    ]

    var customSaveHandler = (_dt, modelName, params) => { 
        if (modelName == "hourlist") {
            _dt.search(); // refresh
        }
    }

    var dt = useDataTable("dt_project_hours", "project_hours", api, headers, {
        // hideDefaultFooter: true,
        // noPager: true,
        itemsPerPageOptions: [ 100, 200, 500],
        itemsPerPage: 200,

        itemName: {
            prefix: 'De',
            single: 'uren',
            plural: 'uren',
        }, parent: {
            id_project: props.model.id
        },
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        customSaveHandler:    customSaveHandler,
        canArchive: false,
        filter:filter.value,
        filter: {
            id_employee: ()=>filter.value.id_employee,
            week: ()=>filter.value.week,            
            id_project: props.model.id
        },
        onCheckboxed: (v) => {
            props.model._disabled=v
        }

    })

    async function onPdf() {
        var filename = `urenlijst ${props.model.pro_number}.pdf`;

        await prompt.value.setData({filename: filename, show_birthday: !!prompt.value.show_birthday, show_bsn: !!prompt.value.show_bsn, group: prompt.value.group||'day'})
                          .prompt( async (data) => {
                                    data.id_project = props.model.id;
                                    data.ids = dt.selectedIDs();
                                   // console.log('pdf data: ', data)
                                    dlg.pdf( async () => await api.postRaw(`pdf`, data));  
                                })

    }

    async function onExportExcel() {
        var name = `urenlijst ${props.model.pro_number}.xlsx`;
        name = await noty.tryPrompt("Bestandsnaam", "Naam van excel document", name);
        if (false === name) {
            return;
        }
        name = (name||"").toLowerCase().replaceAll(".xlsx", "");
        dt.toExcel(name);
    }

</script>
import { clsModel, fnCreate } from '@cls/clsModel'
import { customfields as api } from '@/app/api'
import string from '@lib/string'

var modelName = "customfield";
const fields = ["id", "id_entity", "id_optimit_type", "type", "name", "description", "default", "values", "mandatory"];

class clsCustomField extends clsModel {

    id_optimit_type  = null;
    id_entity        = null;

    _type            = null;
    name            = null;
    description     = null;
    default        = null;
    _values          = null;

    mandatory       = null;

    display = {
        mandatory:    true,     
        isMandatory:  true,           
        defaultvalue: true,        
        types: ["text","time","combo"],
    }

    get types() {        
        var showAll = !this.display?.types?.length;
        var allTypes = [
            { id: "checkbox",   name: "Checkbox"},
            { id: "text",       name: "Tekst"},
            { id: "combo",      name: "Combobox"},
            { id: "amount",     name: "Bedrag"},
            { id: "number",     name: "Getal"},
            { id: "percentage", name: "Percentage"},
            { id: "date",       name: "Datum"},
            { id: "time",       name: "Tijd"},            
        ];
        if (showAll) {
            return allTypes;
        }
        return allTypes.filter( (t)=>this.display.types.find((dt)=>dt==t.id));
    }

    get values() {
        return this._values;
    }    
    set values(value) {
        this._values = value;
        if (this.isFilling) {
            return;
        }
        this.default = null;
    }

    get type() {
        return this._type;
    }    
    set type(value) {
        this._type = value;
        if (this.isFilling) {
            return;
        }

        switch (value) {
            case "checkbox":   this.default = false; break;
            case "text":       this.default = ""; break;
            case "longtext":   this.default = ""; break;
            case "combo":      this.default = ""; break;
            case "amount":     this.default = 0; break;
            case "percentage": this.default = 0; break;
            case "number":     this.default = 0; break;
            case "date":       this.default = ""; break;
            case "time":       this.default = 0; break;    
        }

    }    
    // Load by a number of parameters
    // Note that extraData has preference because params can contain data which is not intended for 
    // use in the api.
    apiLoad(id, params, extraData) {
        var loadParams = extraData || params ||{};
        if (!params.id && id) {
            params.id = id;
        }
        return this.api.loadByParameters(loadParams);
    }

    get showDefaultValue() {
        if (!this.display.defaultvalue) {
            return false;
        }
        if (this.type =="combo" && !this.values) {
            return false;
        }
        return true;
    }
    get modelRep() {
        return this.name;
    }    

    /**
     * Convert the comma separated values to combobox values
     */
    get comboItems() {
        var values = string.split(this.values, ",");
        return values.map( (x) => { return {id: x, name: x} });
    }

    fill(data) {
        data.type = data.type || "text";
        data.mandatory = !!data.mandatory;
        super.fill(data);
    }

    /**
     * override the implementation when required 
     */
    async onAfterLoad(data, params) {

        params = params || {};
        this.id_optimit_type  = params.id_optimit_type;
        this.id_entity        = params.id_entity;

        if (string.isEmpty(this.id_optimit_type)) {
            throw "id_optimit_type is mandatory for custom fields.";
        }

        var display = params.display || {};
        this.display.mandatory = (undefined == display.mandatory) ? true : !!display.mandatory;
        this.display.defaultvalue = (undefined == display.defaultvalue) ? true : !!display.defaultvalue;
        if (!this.display.mandatory) {
            this.mandatory = !!display.isMandatory;
        }
        this.display.types = (undefined == display.types) ? [] : display.types;
    }


    /**
     * Also include the id_entity and id_optimit_type in the data
     */
    toJSON() {
        var json = {
            id:             this.id, 
            id_entity:      this.id_entity,
            id_optimit_type:this.id_optimit_type,
            type:           this.type,
            name:           this.name,
            description:    this.description,
            values:         this.values,
            mandatory:      !!this.mandatory
        };
        if (this.display.defaultvalue) {
            json.default = `${this.default}`; // must be a string.
        }

        return json;
    }
    

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsCustomField , 'clsCustomField');

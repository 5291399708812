<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>

            <Form>
                <FormRow label="Naam">                    
                    <TextField dusk="name" placeholder="Naam" v-model="model.name" :model="model" :rules="model.rules.mandatory" ></TextField>
                </FormRow>
                <FormRow label="Omschrijving">                    
                    <TextField dusk="description" placeholder="Omschrijving" v-model="model.description" :model="model" :rules="model.rules.mandatory" ></TextField>
                </FormRow>
                <FormRow label="Type">                    
                    <ActionCombo dusk="type" v-model='model.type' :model='model' :items='model.types' :rules='model.rules.mandatory' ></ActionCombo>
                </FormRow>
                <FormRow v-if="model.type=='combo'" label="Waarden">                    
                    <TextField dusk="values" placeholder="waarde1,waarde2,..." v-model="model.values" :model="model" :rules="model.rules.values" ></TextField>
                </FormRow>
                <FormRow v-if="model.display.mandatory" label="Veld is verplicht">                    
                    <Checkbox switcher dusk="mandatory" :model='model' v-model='model.mandatory'></Checkbox>
                </FormRow>
                <v-divider v-if="model.showDefaultValue" class="mb-2" ></v-divider>
                <FormRow v-if="model.showDefaultValue" label="Standaard waarde">    
                    <Checkbox dusk="checkboxdefault" switcher v-if="model.type=='checkbox'" :model='model' v-model='model.default'></Checkbox>
                    <TextField v-else-if="model.type=='text'" placeholder="Optioneel" :model='model' :rules='model.rules.default' v-model='model.default'></TextField>
                    <NumberField v-else-if="model.type=='number'" decimal dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <NumberField v-else-if="model.type=='percentage'" percentage dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <NumberField v-else-if="model.type=='amount'" amount dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <ActionCombo v-else-if="model.type=='combo'" dusk="default" :model="model" :items='model.comboItems' :rules='model.rules.default' v-model='model.default' ></ActionCombo>                    
                    <DatePicker  v-else-if="model.type=='date'" dusk="default" :model="model" v-model='model.default' ></DatePicker>
                    <TimeField v-else-if="model.type=='time'" dusk="default" :model='model' v-model='model.default'></TimeField>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '../model/customfield'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    import NumberField from '@controls/input/NumberField'
    import ActionCombo from '@controls/combo/ActionCombo'
    import TimeField from '@controls/input/TimeField'
    import DatePicker from '@controls/input/DatePicker'

    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const types = [
        { id: "checkbox",   name: "Checkbox"},
        { id: "text",       name: "Tekst"},
        { id: "combo",      name: "Combobox"},
        { id: "amount",     name: "Bedrag"},
        { id: "number",     name: "Getal"},
        { id: "percentage", name: "Percentage"},
        { id: "date",       name: "Datum"},
        { id: "time",       name: "Tijd"},            
    ];

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("customfield", "Invoerveld", model, [form])).value;

    window.csdlg = dlg;

</script>

/**
 * Export all api's which are used in the application. 
 * 
 * The exports are exported as is by the @app/api component.
 * 
 * import {takeouts as api} from '@app/api'
 * 
 * var obj = api.load(123)
 *
 * 
 */

// Defualt 'crud' api with support for paging, loading, saving, etcetera.
import clsApi from '@cls/clsApi'
import clsApiPost from '@cls/clsApiPost'

import clsRelationApi from '@/app/api/relation'
import clsRecipeApi from '@/app/api/recipe'
import clsDataApi from '@/app/api/data'
import clsSettingsApi from '@/app/api/settings'
import clsTariffApi from '@/app/api/tariff'
import clsEmployeeApi from '@/app/api/employee'
import clsUserApi from '@/app/api/user'
import clsPaymentOrderApi from '@/app/api/paymentorder'
import clsPurchaseInvoiceApi from '@/app/api/purchaseinvoice'
import clsSalesInvoiceApi from '@/app/api/salesinvoice'
import clsSalesInvoiceRemindersApi from '@/app/api/salesinvoicebulkreminders'
import clsSalesInvoicePaymentApi from '@/app/api/salesinvoicepayment'
import clsPeriodicSalesInvoiceApi from '@/app/api/salesinvoiceperiodic'
import clsAccountancyApi from '@/app/api/accountancy'
import clsFlagApi from '@/app/api/flag'
import clsNoteApi from '@/app/api/note'
import clsTenderApi from '@/app/api/tender'
import clsProjectApi from '@/app/api/project'
import clsSalesOrderApi from '@/app/api/salesorder'

import clsCompanyApi from '@/app/api/company'
import clsSubscriptionApi from '@/app/api/subscription'
import clsSupportApi from '@/app/api/support'
import clsSupportRequestApi from '@/app/api/supportrequest'
import clsSessionApi from '@cls/clsSessionApi'
import clsDashboardApi from '@cls/clsDashboardApi'
import clsWorkorderApi from '@/app/api/workorder'
import clsPlanningApi from '@/app/api/planning'
import clsAccountantApi from '@/app/api/accountant'
import clsMollieApi from '@/app/api/mollie'
import clsProjectPhase from '@/app/api/projectphase'

// The session api. All session / authorisation related api calls. 
const session            = new clsSessionApi("");

// Access to data lists - e.g. units, currencies, etcetera.
const data               = new clsDataApi('/api/data');
// Access to company settings - e.g. invoice settings, invoice layout
const settings           = new clsSettingsApi('/api/settings');
// Access to the support api
const support            = new clsSupportApi('/api/support');
const supportrequest     = new clsSupportRequestApi('/api/support/request');
const help               = new clsApiPost('/api/help');

// Specific data api's.
const units              = new clsApi('/api/masterdata/units');
const departments        = new clsApi('/api/masterdata/departments');
const jobtitles          = new clsApi('/api/masterdata/jobtitles');
const dayparts           = new clsApi('/api/masterdata/dayparts');
const absencetypes       = new clsApi('/api/masterdata/absencetypes');
const workordertypes     = new clsApi('/api/masterdata/workordertypes');
const contracttypes      = new clsApiPost('/api/contract/type');
const costtypes          = new clsApi('/api/masterdata/costtypes');
const hourtypes          = new clsApiPost('/api/masterdata/hourtypes');
const disputes           = new clsApi('/api/masterdata/disputes');
const creditrestrictions = new clsApi('/api/masterdata/creditrestrictions');
const relations          = new clsRelationApi('/api/relation');
const relation_contacts  = new clsApi('/api/relation/contacts');
const relation_addresses = new clsApi('/api/relation/addresses');
const relation_incasso   = new clsApiPost('/api/relation/incasso');
const notes              = new clsNoteApi('/api/note');
const attachments        = new clsApi('/api/attachments');
const mandays            = new clsApi('/api/mandays');
const products           = new clsApi('/api/products');
const recipes            = new clsRecipeApi('/api/recipes');
const vendors            = new clsApi('/api/product/vendors');
const suppliers          = new clsApi('/api/product/suppliers');
const productgroups      = new clsApi('/api/product/groups');
const tariffs            = new clsTariffApi('/api/masterdata/tariffs');
const rightsgroups       = new clsApi('/api/settings/rightsgroups');
const employees          = new clsEmployeeApi('/api/settings/employees');
const users              = new clsUserApi('/api/company/users');
const company            = new clsCompanyApi('/api/company');
const subscription       = new clsSubscriptionApi('/api/company/subscription');
const purchaseinvoices   = new clsPurchaseInvoiceApi('/api/purchase/invoice');
const purchaseinvoice_logs = new clsApi('/api/purchase/logs');
const paymentorders      = new clsPaymentOrderApi('/api/purchase/paymentorder');
const projects           = new clsProjectApi('/api/project');
const projectgroups      = new clsApi('/api/project/group');
const projectphases      = new clsProjectPhase('/api/project/phase');
const project_extrawork  = new clsApiPost('/api/project/extrawork');
const project_othercost  = new clsApiPost('/api/project/othercost');
const project_costlimit  = new clsApiPost('/api/project/costlimit');
const project_chapter    = new clsApiPost('/api/project/chapter');
const project_hours      = new clsApiPost('/api/project/hours');

const salesorderterms    = new clsApiPost('/api/salesorder/term');
const salesorder         = new clsSalesOrderApi('/api/salesorder');

const salesinvoices      = new clsSalesInvoiceApi('/api/salesinvoice');
const salesinvoice_payments = new clsSalesInvoicePaymentApi('/api/salesinvoice/payments');
const salesinvoice_logs = new clsApi('/api/salesinvoice/logs');
const salesinvoice_reminders = new clsSalesInvoiceRemindersApi('/api/salesinvoice/bulkreminders');
const periodic_salesinvoices = new clsPeriodicSalesInvoiceApi('/api/salesinvoice/periodic');

const tenders            = new clsTenderApi('/api/tender');
const tender_logs        = new clsApi('/api/tender/logs');
const tender_versions    = new clsApi('/api/tender/versions');
const hourlist           = new clsApiPost('/api/hours/list');
const hourline           = new clsApiPost('/api/hours/line');
const standardtexts      = new clsApi('/api/masterdata/standardtexts');
const emailtemplates     = new clsApi('/api/settings/emailtypes');
const emailsettings      = new clsApi('/api/settings/email');
const accountancy        = new clsAccountancyApi('/api/integrations/accountancy');
const flags              = new clsFlagApi('/api/flag');
const dashboard          = new clsDashboardApi('/api/dashboard')
const workorders         = new clsWorkorderApi('/api/workorder')
const planning           = new clsPlanningApi('/api/planning')
const customfields       = new clsApiPost('/api/customfields');
const linkemail          = new clsApi('/api/integrations/linkemail');
const tendergroups       = new clsApi('/api/settings/tendergroups');

const accountant         = new clsAccountantApi('/api/accountant');
const kvk                = new clsApi('/api/integrations/kvk');
const mollie             = new clsMollieApi('/api/company/mollie');



export {
    session             ,
    data                ,
    units               , 
    creditrestrictions  ,
    relations           ,
    relation_contacts   ,
    relation_addresses  ,
    attachments         ,
    notes               ,
    mandays             ,
    settings            ,
    products            ,
    recipes             ,
    vendors             ,
    suppliers           ,
    productgroups       ,
    tariffs             ,
    rightsgroups        ,
    employees           ,
    users               ,
    departments         ,
    jobtitles           ,
    costtypes           ,
    hourtypes           ,
    disputes            ,
    purchaseinvoices    ,
    purchaseinvoice_logs,
    paymentorders       ,
    projects            ,
    salesinvoices       , 
    periodic_salesinvoices,
    tenders             , 
    hourlist            , 
    hourline            , 
    standardtexts       ,
    emailtemplates      ,
    emailsettings       ,
    accountancy         , 
    kvk                 ,
    flags               ,
    company             ,
    subscription        ,
    support             ,
    supportrequest      ,
    dashboard           ,
    workorders          ,
    dayparts            ,
    absencetypes        ,
    workordertypes      ,
    customfields        ,
    salesinvoice_payments,
    salesinvoice_logs   ,
    salesinvoice_reminders, 
    planning            ,
    linkemail           ,
    tendergroups        ,
    accountant          ,
    mollie              ,
    tender_logs         ,
    tender_versions     ,
    projectgroups       ,
    projectphases       ,
    project_extrawork   ,
    project_othercost   ,
    project_costlimit   ,
    project_chapter     ,
    project_hours       ,
    salesorderterms     ,
    salesorder          ,
    help                ,
    relation_incasso    ,
    contracttypes       ,
} 